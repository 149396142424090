.image-preview {
    position: relative;
    text-align: center;
    max-height: 576px;
    min-height: 576px;
}

.image-preview>img {
    width: 768px;
    height: auto;
}

@media(max-width:768px) {
    .image-preview>img {
        width: 100%;
    }
}

/* fullscreen enable by props */
.image-preview-fullscreen>img {
    width: 100vw;
    height: 100vh;
}

#outer-circle {
    display: none;
}

.camera-container {
    max-height: 576px;
    min-height: 576px;
}
